.feedback {
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 1rem;
  }
  .feedback__container {
    background-color: rgba(45, 108, 180, 0.1);
    text-align: center;
    max-width: 685px;
    padding: 2rem 1.5rem;
    margin: 2rem auto;
     
    .form {
      .contactInput {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (max-width: 663px) {
          justify-content: center;
        }
        .kontactBtn {
          margin-top: 1rem;
        }
      }
      textarea {
        padding: 1rem;
        background-color: $bg;
        width: 100%;
      }
    }
    .kursPlan {
      padding-bottom: 2rem;
      h2 {
        color: $light;
        font-size: 2rem;
        padding-bottom: 1.5rem;
        text-align: center;
      }
      .kursPlan_des {
        background-color: rgba(45, 108, 180, 0.1);
        padding: 0rem 4rem;

        @media (max-width: 768px) {
          padding: 0.5rem;
        }
        .title {
          display: flex;
          align-items: center;
          font-style: italic;
          border-bottom: 2px solid $light;
          @media (max-width: 468px) {
            font-size: 1rem;
          }
          svg {
            margin-right: 10px;
          }
        }
      }
    }
  }
  
}
