.datenschutz {
  padding: 3rem;
  .datenschutz_title {
    text-align: center;
    background-color: $light;
  }
  .datenschutz_contant {
    padding-top: 2rem;
    .datenschutz_item {
      padding-bottom: 1.5rem;
      .datenschutz_subtitle {
        padding-bottom: 0.5rem;
        font-style: italic;
      }
      .datenschutz_text {
        margin-bottom: 0.5rem;
      }
    }
  }
}
