.kontact {
  background-color: $primaryColor;
  text-align: center;
  max-width: 685px;
  padding: 2rem 1.5rem;
  margin: 2rem auto;
  .form {
    .contactInput {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (max-width: 663px) {
        justify-content: center;
      }
      .kontactBtn {
        margin-top: 1rem;
      }
    }
    textarea {
      padding: 1rem;
      background-color: $bg;
      width: 100%;
    }
  }
}
