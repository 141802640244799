.popular {
.container{
  display: flex;
    justify-content: center;
    flex-direction: column;
  .popular_section {
    text-align: center;

  //  margin-top: 1rem;
    .secTitle {
      color: $dark;
      line-height: 1.5rem;
      padding-bottom: 1.5rem;
      text-transform: uppercase;

    }
    p {
      font-size: 15px;
      color: $primaryColor;
    }
  }
  .mainContent {
    display: flex;
    justify-content:space-around;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
      justify-content: center;
      display: block;
    }
    .singInDestination {
      width: 320px;
      // height: 290px;
      overflow: hidden;
      border-radius: 1rem;
      box-shadow: 0 2px 8px 2px $primaryColor;
      margin: 25px 0;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .destImage {
        height: 200px;
        width: 100%;
        position: relative;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .overlayInfo {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background: linear-gradient($bg, rgba(45, 108, 180, 0.3));
          bottom: 0;
          width: 100%;
          height: 0;
          overflow: hidden;
          h3 {
            color: $dark;
            font-weight: 600;
            font-size: 1.1rem;
          }
        }
      }
      .destFooter {
        padding: 0.6rem 1rem;
        .number {
          font-size: 1.5rem;
          font-weight: 700;
          color: $primaryColor;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items:center;

          .icon {
            position: absolute;
            right: 10px;
            bottom: 0px;
            padding: 0.5rem;
            background: $light;
            font-size: 2.5rem;
            border-radius: 50%;
            color: $greyBg;
            border: 1px solid transparent;
            transition: 0.3s ease;
            &:hover {
              background: $hoverColor;
              border: 1px solid $light;
              color: $bg;
            }
          }
        }
        .desText {
          justify-content: space-between;
          h6 {
            color: $primaryColor;
            font-size: 16px;
          }
          .flex {
            font-size: 16px;
            font-weight: 600;
            align-items: flex-start;
            transform: translateY(5px);
          }
        }
      }
      &:hover {
        .overlayInfo {
          overflow: visible;
          padding: 1rem 1.5rem;
          height: 100%;
        }
      }
    }
  }
}
}
