@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Kaushan+Script&family=Montserrat:wght@400;700&family=Poppins:wght@400;500;600;700;800&display=swap");

$Poppins: "Poppins", sans-serif;
//colors
$bg: #ebf9f9;
$dark: rgb(14, 35, 156);
$light: #35c759; // rgb(157, 255, 0);
$hoverColor: rgb(84, 227, 70);

$blackColor: rgb(6, 26, 48);
$primaryColor: rgba(45, 108, 180, 0.3);
$inputText: rgb(2, 2, 1);
$textColor: rgba(45, 108, 180);
$footerBg: rgb(208, 231, 247);
$greyBg: #ffff;

$spacing-md: 16px;
$spacing-lg: 32px;
$borderRedius: 12px;
$bgImg: "bgimg.jpeg";
$breakpoints: (
  "tablet": 768px,
) !default;
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  transition: 0.3s ease;
}

body {
  width: 100%;
  color: $dark;
  background-color: $bg;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.section {
  padding: 4rem 0 2rem;
}

.container {
  max-width: 1240px;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.icon {
  font-size: 2rem;
  cursor: pointer;
  color: $light;
}

.flex {
  display: flex;
  align-items: center;
}

.grid {
  display: flex;
  align-items: center;
}

.btn {
  padding: 1rem 2.5rem;
  background-color: $light;
  border: none;
  border-radius: 3rem;
  cursor: pointer;
  font-size: 1rem;
  color: $greyBg;

  &:hover {
    background-color: $hoverColor;
  }
}

.btn a {
  color: $dark;
  font-weight: 500;
}

.btn:hover a {
  color: $bg;
}

.secIntro {
  max-width: 70%;
  text-align: center;
  margin: 0 auto 2rem;
}

.secIntro .secTitle {
  color: $blackColor;
  line-height: 1.5rem;
  padding-bottom: 0.5rem;
}

.secIntro p {
  font-size: 13px;
  color: $textColor;
  font-weight: 500;
}

img,
video {
  width: 100%;
  height: auto;
}
