.kursBuchen {
  background-color: $primaryColor;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem;
  @media (max-width: 768px) {
    padding: 1rem;
  }
  a,
  a:visited {
    color: $dark;
    font-style: italic;
    font-weight: bold;
    &:hover {
      color: $light;
    }
  }
  .container {
    background-color: $bg;
    .kursBuchen_form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (max-width: 648px) {
        justify-content: center;
      }

      .MuiFormControl-root.MuiTextField-root.css-yfcmxe-MuiFormControl-root-MuiTextField-root {
        @media (max-width: 648px) {
          width: 100%;
        }
      }
    }
    h1 {
      margin: 2rem 0;
      @media (max-width: 620px) {
        text-align: center;
      }
    }
    form {
      padding: 1rem 0;
      @media (max-width: 620px) {
        text-align: center;
      }
      p {
        display: block;
      }
      .btn {
        @media (max-width: 560px) {
          width: 100%;
        }
      }
    }
    .text {
      font-size: 14px;
      margin-bottom: 2rem;
    }
    .daten {
      margin-bottom: 2rem;
      font-size: 14px;
    }
    .inputText {
      background-color: $bg;
      padding: 1rem;
      margin: 2rem 0;
      width: 100%;

      &:hover {
        background-color: $primaryColor;
      }
    }
    .checkbox {
      display: flex;
      margin-bottom: 2rem;
      font-size: 14px;
      align-items: center;
      margin-left: -12px;
    }
  }
}
