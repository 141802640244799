.agb{
  padding: 2rem;
  .agb_title{
    text-align: center;
    background-color:$light;
  }
  .agb_contant{
    padding-top: 2rem;
  .agb_item{
    padding-bottom: 1.5rem;
    .agb_subtitle{
      padding-bottom: 0.5rem;
      font-style: italic;
    }
   
  }
}
}