.footer {
  background: $footerBg;
  padding: 2rem 0;
  .footer_title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .footer_inner {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      padding-bottom: 10px;
      margin-bottom: 20px;
      border-bottom: 1px solid $light;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
      }
      .footer_inner-logo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 120px;
        .footerLogo {
          padding: 0.5rem;
          .logo {
            color: $dark;
            font-weight: 500;
            cursor: pointer;
            align-items: center;
            justify-content: center;

            .footerLogo_title {
              display: flex;
            }
          }
        }
      }
    }
  }

  .footer_navbar {
    .linkTitle {
      padding-bottom: 0.5rem;
      color: $dark;
      font-weight: 700;
    }
  }
}

.footer_contacts {
  display: flex;
  flex-direction: column;

  a {
    color: $dark;

    &:hover {
      transform: translateX(10px);
      color: $light;
    }
  }
  .icon {
    font-size: 30px;
    background: $light;
    border-radius: 50%;
    padding: 8px;
    color: $greyBg;

    &:hover {
      background: $bg;
    }
  }
}

.footer_rolls {
  display: flex;
  flex-direction: column;
  ul {
    display: flex;
    justify-content: center;
    max-width: 100px;
    margin: auto;
    gap: 2rem;
  }
  div {
    margin: auto;
    font-weight: 300;
    font-size: small;
    a:hover {
      color: $light;
    }
  }
}

.footer_inner-logo,
.footer_navbar,
.footer_contacts {
  margin-top: 1rem;
}
.footerR {
  color: $dark;

  &:hover {
    color: $light;
  }
}
.footerR_active {
  color: $light;
}
