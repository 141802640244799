.logoDiv {
  .logoIcon {
    display: flex;
    flex-direction: row;
    &:hover {
      color: $dark;
    }
    .flexIcon {
      display: flex;
      flex-direction: column;
      span {
        color: $dark;
      }
    }
  }
}
.navBarSection {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    max-width: 1240px;
    height: 80px;
    .navBar {
      .navLists {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        .navItem {
          .navLink {
            color: $dark;
            padding: 0 0.45rem;
            font-size: 0.9rem;
            font-weight: 600;
            &:hover {
              color: $light;
            }
          }
        }
      }
    }
    .logoDiv {
      box-shadow: 0 3px 2px 0 $light;
      .logo {
        color: $dark;
        font-weight: 500;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        .icon {
          color: $light;
          font-size: 25px;
          margin-right: 10px;
        }
      }
    }
    .toggleNavbar {
      display: none;
      .icon {
        font-size: 25px;
        color: $dark;
        &:hover {
          color: $light;
        }
      }
    }
    .closeNavbar {
      display: none;
      position: absolute;
      top: 1rem;
      right: 1.5rem;
      color: $hoverColor;
      &:hover .icon {
        color: ($light);
      }
    }
    @media screen and (max-width: 768px) {
      .toggleNavbar,
      .closeNavbar {
        display: block;
      }
      .navBar {
        background: $greyBg;
        position: absolute;
        height: max-content;
        padding: 1rem;
        width: 100%;
        top: 0;
        left: -100%;
        box-shadow: 0 2px 4px $light;
        transition: 0.5s ease;
        z-index: 10;
        .navLists {
          flex-direction: column;
          justify-content: center;
          width: 100%;
          margin: auto;
          .navItem {
            padding: 1rem 0;
          }
        }
      }
      .activeNavbar {
        left: 0;
      }
    }
    //----------------------------------
  }
  .activeHeader {
    background: $greyBg;
    box-shadow: 0 2px 8px 2px 2px $dark;
  }
}
.navLink {
  color: $primaryColor;
  font-size: 0.9rem;
  font-weight: 600;
  &:hover {
    color: $light;
  }
}
.navLink_active {
  color: $light;
  font-size: 0.9rem;
  font-weight: 600;
}
