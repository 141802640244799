.courses {
  padding: 3rem 0;
  .courses_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .secTitle {
      font-weight: 700;
      font-size: 2rem;
      text-align: center;
      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
      .icon {
        margin-right: 3rem;
        font-size: 3rem;
      }
    }
    .courses_description {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 3rem;
      flex-wrap: wrap;

      .description {
        padding: 2rem;
        .courses_title {
          color: $dark;
          font-weight: 600;
          font-size: 1.5rem;
        }
        span {
          font-size: 1.5rem;
          font-weight: 500;
          font-style: italic;
        }
        .amenities {
          display: flex;
          padding: 2rem 0;
          flex-wrap: wrap;

          .singleAmenity {
            display: flex;
            align-items: center;
            padding-right: 2rem;

            span {
              font-size: 1rem;
              padding-left: 0.5rem;
            }
          }
        }
      }

      img {
        width: 100%;
        height: 200px;
      }
    }
    .about_cours {
      .courses_title {
        padding: 1rem;
      }
      .courses_rule {
        background-color: $primaryColor;
        text-align: start;
        padding-top: 2rem;
        margin: 2rem 0;
        .courses_item {
          padding: 1rem;
          line-height: 1.5rem;
          letter-spacing: normal;
          .icon {
            padding-top: 0.9rem;
            color: rgb(36, 108, 53);
          }
        }
      }
    }
  }
}
