.kursPlan {
  padding-bottom: 2rem;
  h2 {
    color: $light;
    font-size: 2rem;
    padding-bottom: 1.5rem;
    text-align: center;
  }
  .kursPlan_des {
    background-color: rgba(45, 108, 180, 0.1);
    padding: 0rem 4rem;
    @media (max-width: 768px) {
      padding: 0.5rem;
    }
    .title {
      display: flex;
      align-items: center;
      font-style: italic;
      border-bottom: 2px solid $light;
      @media (max-width: 468px) {
        font-size: 1rem;
      }
      svg {
        margin-right: 10px;
      }
    }
    .info {
      display: flex;
      padding: 1rem;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 468px) {
        display: block;
      }
      .info_item {
        line-height: 2rem;
        color: $textColor;
      }
      .kursInfo {
        @media (max-width: 468px) {
          text-align: center;
        }
      }
      .kursBtn {
        border: none;
        background-color: $light;
        border-radius: 3rem;
        padding: 1rem;
        color: $greyBg;
        cursor: pointer;
        @media (max-width: 468px) {
          margin-top: 20px;
          width: 100%;
        }
        &:hover {
          color: azure;
          background-color: $hoverColor;
        }
      }
    }
  }
}
