@import "./variebes";
@import "./navbar";
@import "./home";
@import "./popular";
@import "./footer";
@import "./curses";
@import "./agb";
@import "./impressum";
@import "./kursPlan";
@import "./baderegeln";
@import "./kontac";
@import"./kursBuchen";
@import"./datenschutz";
@import"./textcarusel";
@import"./starRating";
@import"./feedback";
@media (max-width: 556px) {
  .mainContent {
    grid-template-columns: repeat(2, 1fr);
  }
}



