.impressum {
  padding: 2rem;

  .impressum_title {
    text-align: center;
    background-color: $light;
  }

  .contacts {
    padding: 2rem 0;

    p {
      color: $textColor;
    }

    .title {
      padding: 2rem 0;
      font-style: italic;
      color: $dark;
    }

    .links {
      display: flex;
      flex-direction: column;

      a {
        color: $textColor;
      }

      a:hover {
        transform: translateX(10px);
        color: $light;
      }
    }
  }

  .impressum_contant {
    padding-top: 2rem;

    .impressum_item {
      padding-bottom: 1.5rem;

      .impressum_subtitle {
        padding-bottom: 0.5rem;
        font-style: italic;
      }

      .impressum_text {
        padding-top: 0.5rem;
      }

    }
  }
}