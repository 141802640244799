.baderegeln {
  margin-bottom: 2rem;

  h2 {
    color: $light;
    font-size: 2rem;
    padding-bottom: 1.5rem;
    text-align: center;
  }

  .baderegeln_contant {
    background-color: rgba(45, 108, 180, 0.1);
    padding: 2rem 3rem;
    @media (max-width: 650px) {
      padding: 1rem 1.5rem;
    }
    @media (max-width: 320px) {
      padding: 1rem 0 .5rem;
    }

    .baderegeln_item {
      .text {
        padding-top: 0.5rem;
        @media (max-width: 650px) {
          font-size: 14px;
        }
      }

    }
  }
}